import { H3, Rectangle, Title, Wrapper } from "./style";
import { PoolCard } from "./pool-card";
import { useEffect, useState } from "react";
import DeclineGame from "./modals/decline-game";
import ApproveGame from "./modals/approve-game";
import store from "../../../logic/reducers";
import { GameModel, getGames } from "../../../logic/actions";
import { Game } from "../../../core";
import { useSelector } from "react-redux";

export const PoolList = (): JSX.Element => {
  const { walletConnected } = useSelector((state: any) => state.user);
  const [isShowApprove, setIsShowApprove] = useState<boolean>(false);
  const [isShowDecline, setIsShowDecline] = useState<boolean>(false);
  const [approveID, setApproveID] = useState<number>();
  const [declineID, setDeclineID] = useState<number>();
  const [approveAddress, setApproveAddress] = useState<string>("");
  const [disapproveAddress, setDisapproveAddress] = useState<string>("");
  const [games, setGames] = useState<GameModel[]>();

  useEffect(() => {
    if (walletConnected) {
      findGames();
    }
  }, [walletConnected]);

  function findGames() {
    Game.findGames().then((data) => {
      // setGames(data.games.filter((i : GameModel) => i.status === "Approved"));
      setGames(data.games);
      store.dispatch(getGames(data.games));
    });
  }

  useEffect(() => {
    if (approveID) {
      setIsShowApprove(true);
    }

    if (declineID) {
      setIsShowDecline(true);
    }
  }, [approveID, declineID]);

  const openApproveModal = (id: number, address: string) => {
    setApproveAddress(address);
    setApproveID(id);
  };

  const openDeclineModal = (id: number, address: string) => {
    setDisapproveAddress(address);
    setDeclineID(id);
  };

  const closeApproveModal = () => {
    setIsShowApprove(false);
    setApproveID(undefined);
    setGames(store.getState().games.games);
  };

  const closeDeclineModal = () => {
    setDeclineID(undefined);
    setIsShowDecline(false);
    setGames(store.getState().games.games);
  };

  return (
    <>
      <Title>
        <H3>Welcome, Admin</H3>
        <Rectangle />
      </Title>

      <div className="waiting-list-table">
        <Wrapper>
          {!!games &&
            games.map((i) => (
              <PoolCard
                game={i}
                key={i.id}
                openDeclineModal={openDeclineModal}
                openApproveModal={openApproveModal}
              />
            ))}
          {!games?.length && <H3>No meme info to show</H3>}

          <DeclineGame
            address={disapproveAddress}
            show={isShowDecline}
            closeModal={closeDeclineModal}
            id={declineID}
          />
          <ApproveGame
            address={approveAddress}
            show={isShowApprove}
            closeModal={closeApproveModal}
            id={approveID}
          />
        </Wrapper>
      </div>
    </>
  );
};
