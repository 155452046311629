import styled from "styled-components";
import {Color} from "../../../utils";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 17px;
  font-family: 'Cairo',sans-serif;
  border-radius: 12px;
  max-width: 1320px;
`;


export const ImageWrapper = styled.div`
  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 76%;
    z-index: 0;
  }
`;

export const MainInfoWrapper = styled.div`
  display: flex;
  gap: 60px;
  padding-bottom: 50px;
  flex-wrap: wrap;
  
  @media (min-width: 991px) {
    flex-wrap: nowrap;
  }
`;

export const ProjectAvatar = styled.div`
  width: 162px;
  height: 162px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

export const ProjectInfo = styled.div`
  div {
    font-family: 'Cairo',serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.darkPrimary};
  }
  
  .project-name {
    font-weight: 700;
    font-size: 32px;
    line-height: 56px;
    text-align: left;
    letter-spacing: 0.01em;
    color: ${Color.white};
  }
  
  .project-token {
    padding-bottom: 15px;
  }
  
  .project-status {
    font-weight: 700;
    padding-bottom: 11px;
  }
  
  .project-budget {
    font-weight: 400;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: end;
`;

export const GeneralInfoWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column-reverse;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const AboutProject = styled.div`
  width: 100%;
  padding: 48px 64px 83px;
  border-radius: 24px;
  background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
  backdrop-filter: blur(20px);
  font-family: 'Cairo',serif;

  @media (min-width: 1200px) {
    width: 70%;
  }
`;

export const H2 = styled.h2`
  font-family: inherit;
  font-weight: 700;
  font-size: 32px;
  line-height: 56px;
  letter-spacing: 0.01em;
  color: ${Color.white};
`;

export const Paragraph = styled.p`
  font-family: inherit;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: ${Color.white};
`;

export const ProjectDescription = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.23);
  padding-bottom: 54px;
  margin-bottom: 40px;
`;

export const ProjectAssets = styled.div`
`;

export const ProjectVisualMaterials = styled.div`
  padding-top: 40px;
`;

export const ProjectVideoMaterials = styled.div`
  padding: 64px 0 40px;
`;

export const MaterialsContainer = styled.div`
  display: flex;
  gap: 24px;
  padding-top: 24px;
  flex-wrap: wrap;
`;

export const RightBar = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: 1200px) {
    width: 30%;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

export const CardContainer = styled.div`
  background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
  backdrop-filter: blur(20px);
  border-radius: 24px;
  padding: 44px;
  display: flex;
  gap: 32px;
  flex-direction: column;
  
  .text-small {
    font-family: 'Cairo';
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #9AA1AC;
  }
  
  .big-text {
    font-family: 'Cairo';
    font-weight: 700;
    font-size: 32px;
    line-height: 24px;
    color: #9AA1AC;
  }
`;

export const TokenInfo = styled.div`
  display: flex;
  gap: 16px;
  font-family: 'Cairo',serif;
  
  img {
    border-radius: 50%;
    width: 66px;
  }
`;

export const TokenPrice = styled.div`
  font-family: 'Cairo',serif;
  color: ${Color.darkPrimary};

  .token-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    font-family: inherit;
    padding-bottom: 16px;
  }
  
  .token-price {
    font-weight: 700;
    font-size: 32px;
    line-height: 24px;
    font-family: inherit;
    text-align: left;
  }
`;


