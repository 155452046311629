import styled from "styled-components";
import {Color} from "../../../../utils";
import {NavLink} from "react-router-dom";

export const Wrapper = styled.div<{isActivePole: boolean}>`
  width: 100%;
  background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
  backdrop-filter: blur(20px);
  border-radius: 24px;
  padding: 32px;
  font-family: 'Cairo',sans-serif;
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  
  @media (min-width: 991px) {
    flex-direction: row;
    flex-wrap: ${(props) => (props.isActivePole ? 'wrap' : 'nowrap')};
  }
  
  img {
    width: 100px;
    height: 100px;
  }
`;

export const H2 = styled.h2`
  font-family: 'Cairo',serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  letter-spacing: 0.01em;
  color:${Color.white};
`;

export const GameInfo = styled.div`
  display: flex;
  
  .wrapper {
    display: flex;
    flex-direction: column;
    font-family: 'Cairo',serif;
    margin-left: 24px;
    width: 210px;
  }
  
  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.darkPrimary};
    padding-bottom: 8px;
  }
`;

export const About = styled.div`
  padding: 10px 0;
  
  @media (min-width: 991px) {
    padding: 0 34px;
  }
  
  .description {
    color: ${Color.white};
    font-weight: 400;
    font-size: 16px;
    line-height: 24spx;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 650px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
    @media (min-width: 991px) {
      font-size: 20px;
      line-height: 32px;
    }
  }
`;

export const DateWrapper = styled.div`
  font-family: 'Cairo',serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  align-self: center;
  width: 200px;
  color: ${Color.white};
  padding: 32px 0;
  
  @media (min-width: 991px) {
    padding: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;
  
  @media (min-width: 991px) {
    flex-wrap: nowrap;
  }
  
  div {
    display: flex;
    gap: 32px;

    .btn-action-link {
      display: inline-block;
      padding: 22px 22px;
      background: rgba(255, 255, 255, 0.02);
    }
  }
`;

export const BorderLine = styled.div`
  height: 90px;
  border: 1px solid #494949;
  display: none;
  
  @media (min-width: 991px) {
    display: block;
  }
`;

export const Link = styled(NavLink).attrs((props) => ({
  activeClassName: props.activeClassName || 'active',
}))`
  display: block;
  align-items: center;
  text-decoration: none;
  padding: 10px 20px;
  color: white;
  border-right: 4px solid transparent;
  background: transparent;
  &.${(props) => props.activeClassName} {
    color: ${Color.cyanPrimary};
    background: ${Color.lightBluePrimary};
    border-right: 4px solid ${Color.cyanPrimary};
  }

  @media (min-width: 991px) {
    display: inline-flex;
  }
`;

