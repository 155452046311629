import store from "../logic/reducers";
import wallet from "./wallet";
import { ContractAddress } from "./address";
import { Networks } from "./constants";
const poolFactory = require("./abis/pool-factory.json");
const pools = require("./abis/pool.json");
const TierAbi = require("./abis/tier.json");
const poolToken = require("./abis/pooltoken.json");
const tokenAbi = require("./abis/user-token.json");
const poolAbi = require("./abis/pool.json");


export const getpoolFactoryContract = () => {
  const { network } = store.getState().user;
  switch (network) {
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        poolFactory["abi"],
        ContractAddress.sepolia.poolFactory
      );

    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        poolFactory["abi"],
        ContractAddress.mainnet.poolFactory
      );

    default:
      return new wallet.web3.eth.Contract(
        poolFactory["abi"],
        ContractAddress.mainnet.poolFactory
      );
  }
};

export const getPoolsContract = () => {
  const { network } = store.getState().user;
  switch (network) {
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        poolFactory["abi"],
        ContractAddress.sepolia.poolFactory
      );

    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        poolFactory["abi"],
        ContractAddress.mainnet.poolFactory
      );

    default:
      return new wallet.web3.eth.Contract(
        poolFactory["abi"],
        ContractAddress.mainnet.poolFactory
      );
  }
};

export const getPoolDetailsContract = (address: any) => {
  return new wallet.web3.eth.Contract(pools["abi"], address);
};

export const getPoolTokenDetailsContract = (address: string) => {
  return new wallet.web3.eth.Contract(poolToken["abi"], address);
};

export const getsettlerLotBalance = () => {
  return new wallet.web3.eth.Contract(
    TierAbi["abi"],
    ContractAddress.sepolia.settlerLot
  );
};

export const getmerchantLotBalance = () => {
  return new wallet.web3.eth.Contract(
    TierAbi["abi"],
    ContractAddress.sepolia.merchantLot
  );
};

// export const getkingLotBalance = () => {
//   return new wallet.web3.eth.Contract(
//     TierAbi["abi"],
//     ContractAddress.sepolia.kingLot
//   );
// };

// export const getqueenLotBalance = () => {
//   return new wallet.web3.eth.Contract(
//     TierAbi["abi"],
//     ContractAddress.sepolia.queenLot
//   );
// };
// export const getroyaleflashLotBalance = () => {
//   return new wallet.web3.eth.Contract(
//     TierAbi["abi"],
//     ContractAddress.sepolia.royaleFlushLot
//   );
// };

export const createPoolContract = (poolAddress: string): any =>
  new wallet.web3.eth.Contract(poolAbi.abi, poolAddress);

export const createTokenContract = (tokenAddress: string): any =>
  new wallet.web3.eth.Contract(tokenAbi.abi, tokenAddress);
