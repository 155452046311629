/* eslint-disable @typescript-eslint/no-explicit-any */
import { plainToInstance } from 'class-transformer';
import { get } from 'lodash';

import { Api } from '../../../lib/network';
import { BaseModel } from '../base';
import { ADMIN } from './queries';

export class Admin extends BaseModel {
  walletAddress: string;
  nonce: string;

  static fromJson(json: any) {
    return plainToInstance(Admin, json);
  }
  static fromJsonArray(arrJson: any[]) {
    return arrJson ? arrJson.map((x) => Admin.fromJson(x)) : [];
  }

  static async fetchAdminInfo() {
    const { data, ...rest } = await Api.request(ADMIN, {});
    return { data: get(data, 'admin', null), ...rest };
  }
}
