import { useState, Fragment, useEffect } from "react";
import { useWeb3React } from '@web3-react/core'

import { Connect, Accept, Select } from "./components";
import { connectorsByName, ConnectSteps } from "../../utils";
import { WalletTypes, StoreageKey } from "../../utils";
import wallet from "../../utils/wallet";

interface Props {
  show: boolean;
  closeModal: () => void;
}

const LoginModal = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(ConnectSteps.select);
  const [selectedWallet, setSelectedWallet] = useState(WalletTypes.default);
  const { show, closeModal } = props;
  const { library, activate, deactivate } = useWeb3React()

  useEffect(() => {
    if (!show) {
      setCurrentStep(ConnectSteps.select);
    }
  }, [show]);

  const selectWallet = (walletType: number) => {
    connectActivate(walletType)
    setSelectedWallet(walletType);
    setCurrentStep(ConnectSteps.connect);
  };

  const handleAcceptTerms = async (): Promise<void> => {
    try {
      //@ts-ignore
        await wallet.setProvider(selectedWallet ? selectedWallet:1);
        await wallet.login(selectedWallet, library);
        localStorage.setItem(StoreageKey.walletType, selectedWallet.toString());
        closeModal();
        setCurrentStep(ConnectSteps.select);
    }catch (e) {
      console.log("something went wrong in login wallet", e);
      deactivate();
    }
  };

  const handleConnect = async (): Promise<void> => {
    const doNotShowAgain = localStorage.getItem(StoreageKey.doNotShowAgain);
    if (doNotShowAgain === "true") {
      await handleAcceptTerms();
    } else {
      setCurrentStep(ConnectSteps.accept);
    }
  };

  const connectActivate = async (walletType: number) => {
    if (walletType) {
      if (walletType !== 2) {
        activate(connectorsByName['Injected'])
      } else {
        activate(connectorsByName['WalletConnect']);
      };
    }
  }

  const renderContent = () => {
    switch (currentStep) {
      case ConnectSteps.select:
        return <Select selectWallet={selectWallet} />;
      case ConnectSteps.connect:
        return (
          <Connect
            handleConnect={handleConnect}
            selectedWallet={selectedWallet}
          />
        );
      case ConnectSteps.accept:
        return <Accept handleAcceptTerms={handleAcceptTerms} />;
      default:
        return null;
    }
  };

  return <Fragment>{renderContent()}</Fragment>;
};

export default LoginModal;
