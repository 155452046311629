/* eslint-disable jsx-a11y/anchor-is-valid */
import {CloseIconNew} from "../../../../images";
import {H3, Header, ModalWrapper, ModalContainer, CrossIconContainer, ModalBorder, ButtonContainer} from "./style";
import {Game} from "../../../../core";
import store from "../../../../logic/reducers";
import { declineGame} from "../../../../logic/actions";
import wallet from "../../../../utils/wallet";
import { useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";

interface Props {
  address: string;
  show: boolean;
  id: number | undefined;
  closeModal: () => void;
}

const DeclineGame = (props: Props) => {
  const { userAddress } = useSelector((state: any) => state.user);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { address, show, closeModal, id } = props;

  const HandleDeclineGame = async () => {
    if (id) {
      setIsLoading(true);
      const isDone = await wallet.removeAuthorized(userAddress, address);
      if (isDone) {
        Game.decline(+id).then((result) => {
          store.dispatch(declineGame(+id));
          closeModal();
        });
      } else {
        toast("There is an issue with approve meme, Please try again later!", {
          type: toast.TYPE.INFO,
        });
        closeModal();
      }
      setIsLoading(false);
    }
  }

  return (
    <ModalWrapper show={show}>
      <ModalBorder>
        <ModalContainer>
          <Header>
            Decline Meme

            <CrossIconContainer onClick={closeModal}>
              <img src={CloseIconNew.default} alt="close"/>
            </CrossIconContainer>
          </Header>

          <H3>
            Are you sure you want to decline
            this meme?
          </H3>
          <div className="description">
            Facere velit omnis alias optio qui incidunt omnis. Optio vero architecto quia dolor. Voluptates eum nesciunt assumenda provident incidunt necessitatibus libero minima.
          </div>

          <ButtonContainer>
            <a 
              onClick={closeModal} 
              className="btn-action-link bottom-border-none"
            >
              Cancel
            </a>

            {isLoading ? (
              <a className="btn-action-link" aria-disabled>Disapproving...</a>
            ) : (
              <a onClick={HandleDeclineGame} className="btn-action-link">
              Dispprove
            </a>
            )}
            
          </ButtonContainer>
        </ModalContainer>
      </ModalBorder>
    </ModalWrapper>
  );
}

export default DeclineGame;