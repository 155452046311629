import {
  Tier01Icon,
  Tier02Icon,
  Tier03Icon,
  Tier04Icon,
  SETTLER,
  MERCHANT,
  KNIGHT,
  ARCHON,
  MONARCH,
} from "../images";

export const configNetwork = "mainnet";

export const Paths = {
  landingZone: "/",
  adminPanel: "/admin-panel",
  waitingList: "/waiting-list",
  approvedList: "/approved-list",
  safelist: "/safelist",
  authUsers:"/authorized-users",
  liquidityProvider: "/liquidity-provider",
  rptStaking: "/rpt-staking",
  stakingLots: "/staking-lots",
  royaReserve: "/roya-reserve",
  royaAnalytics: "/network-stats",
  buySellRoya: "/roya-swap",
  temrs: "/terms-of-use",
  convertMroya: "/mroya-swap",
  totalValue: "/total-value",
  gameDetails: "/game-details",
  submitGame: "/submit-game",
  poolregistration: "/pool-registration",
  dashboard:"/dashboard",
  details:"/upcoming-projects",
  tokensale:"/token-sale",
  pools: '/pools',
  poolDetails: '/pool',
  createPool: '/create-pool',
  account: '/account',
  allocation: '/setallocation',
  participantwhitelistWallets: '/whitelist-wallets',
  editpool: '/edit-pool'
};

export const PoolsTab = {
  allSales: 1,
  upcomingAndLive: 2,
  applied: 3,
  completed: 4,
};

export const PoolDetailTab = {
  detail: 1,
  about: 2,
};

export const CreatePoolSteps = {
  setPool: 0,
  tokenDetails: 1,
  swapDetails: 2,
  setDates: 3,
  poolAllocation: 4,
};

export const PoolTypes = {
  standard: 'Standard',
  instant: 'Instant',
};

export const AccessType = {
  public: 'public',
  private: 'private',
};

export const AllocationType = {
  limited: 'limited',
  unlimited: 'unlimited',
};

export const WalletTypes = {
  default: 0,
  metamask: 1,
  walletConnect: 2,
  authereum: 3,
  burnerConnect: 4,
  uniLogin: 5,
  mewWallet: 6,
  sepolia:7
};

export const ConnectSteps = {
  select: 1,
  connect: 2,
  accept: 3,
};

export const ModalTypes = {
  closed: 0,
  stake: 1,
  claimMRoya: 2,
  claimRewards: 3,
  withdrawFunds: 4,
  claimStableCoins: 5,
};

export const StakeSteps = {
  confirm: 0,
  processing: 1,
  success: 2,
};

export const WithdrawSteps = {
  confirm: 0,
  success: 1,
};

export const StoreageKey = {
  doNotShowAgain: "doNotShowAgain",
  walletType: "walletType",
};

export const StakingLotModals = {
  closed: 0,
  buysettler: 1,
  buymerchant: 2,
  buyKing: 3,
  buyQueen: 4,
  BuyFlush: 5,
  sellSettler: 6,
  sellMerchant: 7,
  sellKing: 8,
  sellQueen: 9,
  sellFlush: 10,
  queenDiscount: 11,
  queenLockedNft: 12,
};

export const LotRewardProcess = {
  idle: 0,
  settlerReward: 1,
  merchantReward: 2,
  qlotReward: 3,
  klotReward: 4,
  flotReward: 5,
};

export const RtpStakingModal = {
  closed: 0,
  unlockRtp: 1,
  lockRtp: 2,
};

export const RoyalFlushModals = {
  closed: 0,
  acquireLots: 1,
  convertLots: 2,
};

export const LockRptSteps = {
  confirm: 1,
  success: 2,
};

export const UnlockRptSteps = {
  confirm: 1,
  success: 2,
};

export const Config = {
  ropsten: {
    etherscanLink: "https://ropsten.etherscan.io",
    apiUrl: "https://transactionapi.royale.finance",
    defaultGasPrice: "15",
    transactionText: "Transaction Pending",
    iGamingOperator: "https://royalefinance.typeform.com/to/Yy7Nb02c",
    coinGeckoApi: "https://api.coingecko.com/api/v3/coins",
    infuraId: "287b5d14c20f4b7d9411d165fac6a688",
  },
  mainnet: {
    etherscanLink: "https://etherscan.io",
    apiUrl: "https://transactionapiprod.royale.finance",
    defaultGasPrice: "15",
    transactionText: "Transaction Pending",
    iGamingOperator: "https://royalefinance.typeform.com/to/Yy7Nb02c",
    coinGeckoApi: "https://api.coingecko.com/api/v3/coins",
    infuraId: "c7a95b91ffae44e3b7fb80d9fbb98939",
  },
};

export const getConfig = () => {
  return Config[configNetwork];
};

export const SuccessMsgType = {
  hide: 0,
  showBuySuccess: 1,
  showSellSuccess: 2,
};

export const RoyaReserveModal = {
  closed: 0,
  cooldownActivated: 1,
  unstake: 2,
};

export const SocialLinks = {
  twitter: "https://twitter.com/Royale_Finance",
  telegramOfficial: "https://t.me/Royalefinanceofficial",
  telegramAnnouncement: "https://t.me/RoyaleFinance",
  medium: "https://medium.com/@officialroyale",
  docs: "https://royalefinance.gitbook.io/royale-finance-docs/",
  mRoyaSwap: "https://staging.d2brf9r7tb77dk.amplifyapp.com/",
};

export const Networks = {
  mainnet: "0x1",
  ropsten: "0x3",
  rinkeby: "0x4",
  goerli: "0x5",
  sepolia:"0x14a34",
  kovan: "0x2a",
  moonBaseAlpha: "0x507",
  bscTestnet: "0x61",
  bscMainnet: "0x38",
  maticTestnet: "0x13881",
  maticMainnet: "0x89",
};

export const LotPrice = {
  settlerLot: 1000,
  merchantLot: 5000,
  queenLot: 10000,
  kingLot: 50000,
  flushLot: 250000,
};

export const RewardsPerBlock = {
  rpt: 4,
  roya: 0.2,
};

export const EtherscanLinks = {
  ethereum: "https://etherscan.io",
  binance: "https://bscscan.com",
  matic: "https://polygonscan.com",
};

export const NetworkOptions = {
  ethereum: "ethereum",
  matic: "matic",
  binance: "binance",
};

export const SwapProcess = {
  idle: 0,
  inWallet: 1,
  stakeRoya: 2,
};

export interface RoyaStakingInterface {
  settler: RoyaStakeModel,
  merchant: RoyaStakeModel,
  knight: RoyaStakeModel,
  archon: RoyaStakeModel,
  monarch: RoyaStakeModel
}

export interface RoyaStakeModel {
  title: string,
  img: string,
  icon: string,
  price: number,
  description: string,
  stake: number,
  slot: number,
  allocation: number,
  buy: number,
  sell: number
}

export const TierType = [
  'Settler',
  'Merchant',
  'Knight',
  'Archon',
  'Monarch',
]

export const RoyaStaking: RoyaStakingInterface = {
  settler: {
    buy: 1,
    sell: 6,
    title: "Settler",
    img: Tier01Icon.default,
    icon: SETTLER.default,
    price: 1000,
    slot: 150,
    stake: 1000,
    allocation: 30,
    description: "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard"
  },
  merchant: {
    buy: 2,
    sell: 7,
    title: "Merchant",
    img: Tier02Icon.default,
    icon: MERCHANT.default,
    price: 5000,
    slot: 100,
    stake: 5000,
    allocation: 45,
    description: "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard"
  },
  knight: {
    buy: 3,
    sell: 8,
    title: "Knight",
    img: Tier03Icon.default,
    icon: KNIGHT.default,
    price: 10000,
    slot: 80,
    stake: 10000,
    allocation: 60,
    description: "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard"
  },
  archon: {
    buy: 4,
    sell: 9,
    title: "Archon",
    img: Tier04Icon.default,
    icon: ARCHON.default,
    price: 50000,
    slot: 50,
    stake: 50000,
    allocation: 75,
    description: "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard"
  },
  monarch: {
    buy: 5,
    sell: 10,
    title: "Monarch",
    img: Tier03Icon.default,
    icon: MONARCH.default,
    price: 250000,
    slot: 25,
    stake: 250000,
    allocation: 90,
    description: "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard"
  },
}

///// Need to remove later
export const RoyaTier = {
  prince: 25,
  princess: 50,
  queen: 75,
  king: 100,
};
