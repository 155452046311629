import React, { useEffect, useState } from "react";
import { TableHeader, Wrapper, Th, Table, Tr } from "./style";
import { H3, Rectangle, Title } from "../account/pool-list/style";
import { ArrowLeft } from "../../images";
import { useHistory, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Game } from "../../core";
import { GameModel } from "../../logic/actions";
import { TierType } from "../../utils/constants";

const headers = [
  { label: "Fullname", key: "name" },
  { label: "Email", key: "email" },
  { label: "Wallet", key: "user.walletAddress" },
  { label: "Twitter", key: "twitter" },
];
interface Params {
  id: string;
}

const SafeList: React.FC<any> = () => {
  const history = useHistory();

  const { id }: Params = useParams();
  const [safelist, setSafelist] = useState([]);
  const [game, setGame] = useState<GameModel>();

  useEffect(() => {
    if (id) {
      findSafelist(id);
      getGameInfo(id);
    }
  }, [id]);

  const findSafelist = (id: string) => {
    Game.findSafelist(+id).then((safelist) => {
      setSafelist(safelist);
    });
  };

  const getGameInfo = (id: string) => {
    Game.findGame(+id).then((gameInfo) => setGame(gameInfo));
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      <Title onClick={handleBack} asButton={true}>
        <H3>
          <img src={ArrowLeft.default} alt="" />
          Go Back
        </H3>
        <Rectangle />
      </Title>
      <Wrapper>
        <TableHeader>
          <h3>Safelist for {game?.name}</h3>
          <CSVLink
            className="btn-action-link"
            data={safelist}
            filename={`${game?.name}-safelist.csv`}
            headers={headers}
          >
            Download CSV
          </CSVLink>
        </TableHeader>
        <Table>
          <thead>
            <Tr header>
              <Th>Full Name</Th>
              <Th>Email</Th>
              <Th>ERC20/BEP20 Wallet Address</Th>
              <Th>Your Twitter Handle</Th>
              <Th>Tier Title</Th>
            </Tr>
          </thead>
          <tbody>
            {safelist.length > 0 &&
              safelist.map((i: any) => (
                <Tr header key={i.id}>
                  <Th>{i.name}</Th>
                  <Th>{i.email}</Th>
                  <Th>{i.user.walletAddress}</Th>
                  <Th>@{i.twitter}</Th>
                  <Th>{TierType[i.tier]}</Th>
                </Tr>
              ))}
          </tbody>
        </Table>
      </Wrapper>
    </>
  );
};

export default SafeList;
