import React, { useEffect, useState } from "react";
import { TableHeader, Wrapper, Th, Table, Tr } from "./style";
import { H3, Rectangle, Title } from "../account/pool-list/style";
import { ArrowLeft } from "../../images";
import { useHistory, useParams } from "react-router-dom";
import { TierType } from "../../utils";
import { Game } from "../../core";
import { GameModel } from "../../logic/actions";

interface Params {
  id: string;
}

const PublicWhitelist: React.FC<any> = () => {
  const history = useHistory();
  const { id }: Params = useParams();

  const [safelist, setSafelist] = useState([]);
  const [game, setGame] = useState<GameModel>();

  useEffect(() => {
    if (id) {
      findWhitelist(id);
      getGameInfo(id);
    }
  }, [id]);

  const findWhitelist = (id: string) => {
    Game.findPublicWhitelist(+id).then((whitelist) => {
      setSafelist(whitelist);
    });
  };

  const getGameInfo = (id: string) => {
    Game.findGame(+id).then((gameInfo) => setGame(gameInfo));
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      <Title onClick={handleBack} asButton={true}>
        <H3>
          <img src={ArrowLeft.default} alt="" />
          Go Back
        </H3>
        <Rectangle />
      </Title>
      <Wrapper>
        <TableHeader>
          <h3>Public Whitelist for {game?.name}</h3>
        </TableHeader>
        <Table>
          <thead>
            <Tr header>
              <Th>ERC20/BEP20 Wallet Address</Th>
              <Th>Tier Title</Th>
              <Th>Deposit Amount</Th>
              <Th>Claim Amount</Th>
            </Tr>
          </thead>
          <tbody>
            {safelist.length > 0 &&
              safelist.map((i: any) => (
                <Tr header key={i.id}>
                  <Th>{i.walletAddress}</Th>
                  <Th>{TierType[i.tier]}</Th>
                  <Th className="center">{i.deposit}</Th>
                  <Th className="center">{i.claim}</Th>
                </Tr>
              ))}
          </tbody>
        </Table>
      </Wrapper>
    </>
  );
};

export default PublicWhitelist;
