import {
  LeftSidePoolInfo, PoolInfoWrapper,
  PoolName,
  PoolTable,
  PoolTableCol, ProgressBar, RegistrationEndsWrapper,
  RightSidePoolInfo,
  RoundColor
} from "./style";
import {formatDistanceStrict} from "date-fns";

export const PoolInfo = ({poolInfo, isVerticalView}: { poolInfo: any, isVerticalView: boolean }): JSX.Element => {
  const {poolName, publicOrPrivate, minimumCutOffAmount, minimumAllocationPerWallet, maximumAllocationPerWallet} = poolInfo;

  const timeToEnd = () => {
    return formatDistanceStrict(new Date(poolInfo?.utcEndDate as string), new Date(), {
      addSuffix: true,
    })
  }

  const getProgress = () => {
    const startDate = new Date(poolInfo?.utcStartDate as string);
    const endDate = new Date(poolInfo?.utcEndDate as string);
    const total = +endDate - +startDate;
    const elaps = Date.now() - +startDate;
    return Math.round((elaps / total) * 100);
  };

  return (
    <PoolInfoWrapper isVerticalView={isVerticalView}>
      <LeftSidePoolInfo>
        <RoundColor/>
        <PoolName>
          {poolName}
          <span className="pool-type">
            {publicOrPrivate}
          </span>
        </PoolName>
      </LeftSidePoolInfo>
      <RightSidePoolInfo isVerticalView={isVerticalView}>
        <PoolTable isVerticalView={isVerticalView}>
          <PoolTableCol className='raising' isVerticalView={isVerticalView}>
            <div className="col-name">Raising</div>
            <div className="col-data raising">$ {minimumCutOffAmount}M</div>
          </PoolTableCol>
          <PoolTableCol isVerticalView={isVerticalView}>
            <div className="col-name">Min Allocations</div>
            <div className="col-data">{minimumAllocationPerWallet}</div>
          </PoolTableCol>
          <PoolTableCol isVerticalView={isVerticalView}>
            <div className="col-name">Max Allocations</div>
            <div className="col-data">{maximumAllocationPerWallet}</div>
          </PoolTableCol>
        </PoolTable>
        <RegistrationEndsWrapper>
          <div className="time-to-end">
            Registrations ends {timeToEnd()}
          </div>
          <ProgressBar progress={getProgress()}>
            <span className="filled"></span>
          </ProgressBar>
        </RegistrationEndsWrapper>
      </RightSidePoolInfo>
    </PoolInfoWrapper>
  )
}
