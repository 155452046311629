import { Header, Footer } from "./components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Paths } from "./utils";
import { ToastContainer } from "react-toastify";
import "./style/index.scss";
import "react-toastify/dist/ReactToastify.css";
import { PoolList } from "./modules/account/pool-list";
import { ProjectFullInfo } from "./modules/account/project-full-info";
import SafeList from "./modules/safelist/safelist";
import styled from "styled-components";
import AuthUsers from "./modules/auth-users/auth-users";
import PublicWhitelist from "./modules/public-whitelist";
import PrivateWhitelist from "./modules/private-whitelist";
import { useSelector } from "react-redux";
import { H3 } from "./modules/account/pool-list/style";

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  padding: 8rem 0 0;

  @media (min-width: 991px) {
    padding: 16rem 0 0;
  }
`;

const Content = styled.div`
  flex: 1;
  margin: 0 auto;
  max-width: 1300px;
`;

const App = () => {
  const { walletConnected } = useSelector((state: any) => state.user);
  return (
    <Router>
      <Header />
      <Wrapper>
        {walletConnected ? (
          <Content>
            <Switch>
              <Route exact path="/" component={PoolList} />
              <Route path={Paths.authUsers} component={AuthUsers} />
              <Route
                path={`/project-full-info/:id/safelist`}
                component={SafeList}
              />
              <Route
                path={`/project-full-info/:id/public-whitelist`}
                component={PublicWhitelist}
              />
              <Route
                path={`/project-full-info/:id/private-whitelist`}
                component={PrivateWhitelist}
              />
              <Route
                path={`/project-full-info/:id`}
                component={ProjectFullInfo}
              />
            </Switch>
          </Content>
        ) : (
          <H3>Please connect Admin Wallet to get meme list</H3>
        )}
      </Wrapper>
      <Footer />
      <ToastContainer />
    </Router>
  );
};

export default App;
