/* eslint-disable jsx-a11y/anchor-is-valid */
import { useHistory, useParams } from "react-router-dom";
import {
  AboutProject,
  ButtonWrapper,
  CardContainer,
  GeneralInfoWrapper,
  H2,
  ImageWrapper,
  MainInfoWrapper,
  MaterialsContainer,
  Paragraph,
  ProjectAvatar,
  ProjectDescription,
  ProjectInfo,
  ProjectVisualMaterials,
  RightBar,
  TokenInfo,
  TokenPrice,
  Wrapper,
} from "./style";
import {
  DefaultGameImage,
  FullInfoImage,
  ProjectAvatarImage,
} from "../../../images";
import ApproveGame from "../pool-list/modals/approve-game";
import { useEffect, useState } from "react";
// import { VisualMaterial } from "./shared/visual-material/visual-material";
import { ContactInfo } from "./shared/contact-info/contact-info";
import DeclineGame from "../pool-list/modals/decline-game";
import { PoolInfo } from "../components/pool-info";
import store from "../../../logic/reducers";
import { GameModel } from "../../../logic/actions";
import { Game } from "../../../core";

// const mockVisual = [
//   {
//     id: "1",
//     fileName: "image1",
//     fileSize: "20mb",
//     fileType: "image",
//   },
//   {
//     id: "2",
//     fileName: "image2",
//     fileSize: "20mb",
//     fileType: "image",
//   },
//   {
//     id: "3",
//     fileName: "image3",
//     fileSize: "20mb",
//     fileType: "image",
//   },
// ];

// const mockVideo = [
//   {
//     id: "1",
//     fileName: "video.mp4",
//     fileSize: "40mb",
//     fileType: "video",
//   },
//   {
//     id: "2",
//     fileName: "video.mp4",
//     fileSize: "30mb",
//     fileType: "video",
//   },
// ];

const poolInfo = {
  id: "1001",
  maximumAllocationPerWallet: "100",
  minimumAllocationPerWallet: "50",
  minimumCutOffAmount: "10,000",
  utcEndDate: "Thur, 01 April 2023 22:04:05 GMT",
  utcStartDate: "Fri, 10 March 2023 03:04:05 GMT",
  poolName: "Standard Pool",
  publicOrPrivate: "Private",
};

interface Params {
  id: string;
}

export const ProjectFullInfo = (): JSX.Element => {
  const history = useHistory();
  const { id }: Params = useParams();
  const [isShowApprove, setIsShowApprove] = useState<boolean>(false);
  const [isShowDecline, setIsShowDecline] = useState<boolean>(false);
  const [game, setGame] = useState<GameModel>();

  const closeApproveModal = () => {
    setIsShowApprove(false);
  };

  const closeDeclineModal = () => {
    setIsShowDecline(false);
  };

  useEffect(() => {
    const game = store
      .getState()
      .games.games.find((i: GameModel) => +i.id === +id);

    if (game) {
      setGame(game);
    } else {
      findGame(+id);
    }
  }, [id]);

  const findGame = (id: number) => {
    Game.findGame(id).then((game) => {
      setGame(game);
    });
  };

  return (
    <>
      {game && (
        <Wrapper>
          <ImageWrapper>
            <img src={FullInfoImage.default} alt="" />
          </ImageWrapper>

          <MainInfoWrapper>
            <ProjectAvatar>
              <img
                src={
                  game.thumbnail
                    ? game.thumbnail.split(",")[0]
                    : DefaultGameImage.default
                }
                alt=""
              />
            </ProjectAvatar>
            <ProjectInfo>
              <div className="project-name">{game.name}</div>
              <div className="project-token">
                Token Name:&nbsp;
                {game.poolInfo &&
                game.poolInfo.tokenSymbol &&
                game.poolInfo.tokenSymbol.length > 0
                  ? game.poolInfo.tokenSymbol
                  : "Coming Soon"}
              </div>
              <div className="project-status">
                Project Status: {game.status}
              </div>
              <div className="project-budget">
                Total Raise:&nbsp;
                {game.poolInfo?.totalRaise
                  ? game.poolInfo.totalRaise + "BNB"
                  : "TBA"}
              </div>
            </ProjectInfo>

            {!game.isApproved && (
              <ButtonWrapper>
                <a
                  onClick={() => setIsShowApprove(true)}
                  className="btn-action-link"
                >
                  Approve
                </a>
              </ButtonWrapper>
            )}
          </MainInfoWrapper>

          <GeneralInfoWrapper>
            <AboutProject>
              <ProjectDescription>
                <H2>Meme DESCRIPTION</H2>

                <Paragraph>{game.description}</Paragraph>

                <ul className="social-link">
                  {game.twitter && game.twitter.length && (
                    <li>
                      <a href={`https://twitter.com/${game.twitter}`}>
                        <svg
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M24 4.72798C23.1 5.12798 22.2 5.42798 21.2 5.52798C22.2 4.92798 23 3.92798 23.4 2.82798C22.4 3.42798 21.4 3.82798 20.3 4.02798C19.4 3.02798 18.1 2.42798 16.7 2.42798C14 2.42798 11.8 4.62798 11.8 7.32798C11.8 7.72798 11.8 8.12798 11.9 8.42798C7.7 8.22798 4.1 6.22798 1.7 3.22798C1.2 4.02798 1 4.82798 1 5.72798C1 7.42798 1.9 8.92798 3.2 9.82798C2.4 9.82798 1.6 9.62798 1 9.22798C1 9.22798 1 9.22798 1 9.32798C1 11.728 2.7 13.728 4.9 14.128C4.5 14.228 4.1 14.328 3.6 14.328C3.3 14.328 3 14.328 2.7 14.228C3.3 16.228 5.1 17.628 7.3 17.628C5.6 18.928 3.5 19.728 1.2 19.728C0.8 19.728 0.4 19.728 0 19.628C2.2 21.028 4.8 21.828 7.5 21.828C16.6 21.828 21.5 14.328 21.5 7.82798C21.5 7.62798 21.5 7.42798 21.5 7.22798C22.5 6.52798 23.3 5.62798 24 4.72798Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </li>
                  )}

                  {game.telegram && game.telegram.length && (
                    <li>
                      <a href={`https://t.me/${game.telegram}`}>
                        <svg
                          viewBox="0 0 25 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M24.7745 0.559376C24.7552 0.462122 24.7127 0.37223 24.6514 0.298877C24.5902 0.225523 24.5123 0.171336 24.4257 0.141846C24.1106 0.0736005 23.7845 0.0991914 23.4814 0.215964C23.4814 0.215964 2.45364 8.51467 1.25289 9.43373C0.993391 9.63138 0.90714 9.74585 0.86439 9.88173C0.65664 10.5356 1.30314 10.8238 1.30314 10.8238L6.72266 12.7633C6.8142 12.7806 6.90817 12.7744 6.99716 12.7451C8.23016 11.8903 19.4014 4.1442 20.0472 3.88396C20.1484 3.8502 20.2234 3.88396 20.2069 3.96632C19.9429 4.95949 10.2957 14.3733 10.2424 14.4309C10.2165 14.4542 10.1962 14.4841 10.1831 14.5179C10.1701 14.5518 10.1647 14.5886 10.1674 14.6253L9.66341 20.432C9.66341 20.432 9.45191 22.2437 11.0989 20.432C12.2667 19.1481 13.3872 18.0857 13.9489 17.5694C15.8127 18.9817 17.8182 20.544 18.6829 21.3576C18.828 21.5124 19 21.6334 19.1887 21.7135C19.3774 21.7936 19.5788 21.8311 19.7809 21.8237C20.6059 21.7891 20.8309 20.7984 20.8309 20.7984C20.8309 20.7984 24.662 3.87079 24.791 1.60196C24.803 1.37961 24.8202 1.23714 24.8217 1.08479C24.8285 0.908082 24.8126 0.731201 24.7745 0.559376Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </li>
                  )}
                </ul>
              </ProjectDescription>
              {/* <ProjectVisualMaterials>
                <H2>VISUAL MATERIAL</H2>

                <MaterialsContainer>
                  <iframe
                    height="400"
                    src={game.videoLink}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </MaterialsContainer>
              </ProjectVisualMaterials> */}

              <ContactInfo game={game} />
            </AboutProject>

            <RightBar>
              <CardContainer>
                {game.poolInfo ? (
                  <>
                    <TokenInfo>
                      <img src={ProjectAvatarImage.default} alt="" />
                      <H2>DARK Coin</H2>
                    </TokenInfo>
                    <TokenPrice>
                      <div className="token-title">Current Price</div>
                      <div className="token-price">0.34556 USD</div>
                    </TokenPrice>
                    <TokenPrice>
                      <div className="token-title">ATH Price</div>
                      <div className="token-price">0.44556 USD</div>
                    </TokenPrice>

                    <PoolInfo poolInfo={poolInfo} isVerticalView={true} />
                  </>
                ) : (
                  <>
                    <TokenInfo>
                      <H2>Pool Info</H2>
                    </TokenInfo>
                    <TokenPrice>
                      <div className="token-title">Coming Soon</div>
                    </TokenPrice>
                  </>
                )}
              </CardContainer>
              <CardContainer>
                <span className="text-small">Amount of applied users</span>
                <span className="big-text">
                  {game.totalSafelist ? game.totalSafelist : "--"} Users
                </span>
                <a
                  onClick={() => {
                    history.push(`/project-full-info/${id}/safelist`);
                  }}
                  className="btn-action-link"
                >
                  See Safelist
                </a>
                <a
                  onClick={() => {
                    history.push(`/project-full-info/${id}/public-whitelist`);
                  }}
                  className="btn-action-link"
                >
                  See Public Whitelist
                </a>
                <a
                  onClick={() => {
                    history.push(`/project-full-info/${id}/private-whitelist`);
                  }}
                  className="btn-action-link"
                >
                  See Private Whitelist
                </a>
              </CardContainer>
            </RightBar>
          </GeneralInfoWrapper>

          <DeclineGame
            address={game.user!.walletAddress}
            show={isShowDecline}
            closeModal={closeDeclineModal}
            id={+id}
          />
          <ApproveGame
            address={game.user!.walletAddress}
            show={isShowApprove}
            closeModal={closeApproveModal}
            id={+id}
          />
        </Wrapper>
      )}
    </>
  );
};
