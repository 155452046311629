/* eslint-disable jsx-a11y/anchor-is-valid */
import { CloseIconNew } from "../../../../images";
import {
  H3,
  Header,
  ModalWrapper,
  ModalContainer,
  CrossIconContainer,
  ModalBorder,
  ButtonContainer,
} from "./style";
import { Game } from "../../../../core";
import store from "../../../../logic/reducers";
import { approveGame } from "../../../../logic/actions";
import { useSelector } from "react-redux";
import wallet from "../../../../utils/wallet";
import { toast } from "react-toastify";
import { useState } from "react";

interface Props {
  show: boolean;
  id: number | undefined;
  address: string;
  closeModal: () => void;
}

const ApproveGame = (props: Props) => {
  const { userAddress } = useSelector((state: any) => state.user);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { show, closeModal, id, address } = props;

  const HandleApproveGame = async () => {
    if (id) {
      setIsLoading(true);
      const isDone = await wallet.addAuthorized(userAddress, address);
      if (isDone) {
        Game.approve(+id).then((result) => {
          store.dispatch(approveGame(+id));
          closeModal();
        });
      } else {
        toast("There is an issue with approve meme, Please try again later!", {
          type: toast.TYPE.INFO,
        });
        closeModal();
      }
      setIsLoading(false);
    }
  };

  return (
    <ModalWrapper show={show}>
      <ModalBorder>
        <ModalContainer>
          <Header>
            Approve Meme
            <CrossIconContainer onClick={closeModal}>
              <img src={CloseIconNew.default} alt="close" />
            </CrossIconContainer>
          </Header>

          <H3>Are you sure you want to approve this meme?</H3>
          <div className="description">
            Facere velit omnis alias optio qui incidunt omnis. Optio vero
            architecto quia dolor. Voluptates eum nesciunt assumenda provident
            incidunt necessitatibus libero minima.
          </div>

          <ButtonContainer>
            <a
              onClick={closeModal}
              className="btn-action-link bottom-border-none"
            >
              Cancel
            </a>

            {isLoading ? (
              <a className="btn-action-link" aria-disabled>Approving...</a>
            ) : (
              <a onClick={HandleApproveGame}  className="btn-action-link">
              Approve
            </a>
            )}
            
          </ButtonContainer>
        </ModalContainer>
      </ModalBorder>
    </ModalWrapper>
  );
};

export default ApproveGame;
