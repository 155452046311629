import styled from "styled-components";
import {Color} from "../../../../utils";

export const PoolInfoWrapper = styled.div<{isVerticalView : boolean}>`
  display: flex;
  font-family: 'Cairo',sans-serif;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  align-items: normal;

  @media (min-width: 768px) {
    flex-direction: ${(props) => (props.isVerticalView ? 'column' : 'row')};
    width: unset;
    align-items: ${(props) => (props.isVerticalView ? 'start' : 'center')};
  }
`;

export const LeftSidePoolInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const RoundColor = styled.div`
  height: 66px;
  width: 66px;
  position: relative;
  margin-right: 11px;
  border-radius: 50.5px;
  border: 2px solid transparent;

  background: linear-gradient(204.56deg, #EEEEEE 11.73%, #FFFEE2 14.89%, rgba(255, 255, 255, 0.950883) 17.19%, rgba(255, 186, 255, 0.850701) 19.85%, rgba(255, 255, 255, 0.815523) 23%, #79A2F2 27.1%, #20282E 37.71%, #29353C 41.08%, rgba(255, 255, 255, 0.596267) 42.63%, #FFE978 46.18%, #79A2F2 48.96%, rgba(255, 255, 255, 0.741036) 51.39%, #79A2F2 54.15%, #85839C 59.1%, #FFFFFF 64.41%, #51555E 79.9%, #699CFF 84.66%, #FFFFFF 85.89%, #79A2F2 87.16%, #FFFFFF 88.27%);

  &::before {
    content: '';
    width: 63px;
    height: 63px;
    background: ${Color.blackPrimary};
    position: absolute;
    border-radius: 50.5px;
    top: -0.1px;
  }
`;

export const PoolName = styled.div`
  font-family: 'Cairo',sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${Color.white};

  .pool-type {
    font-family: inherit;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${Color.blackPrimary};
    background: ${Color.silver};
    padding: 3px 18px;
    border-radius: 8px;
    display: block;
    width: max-content;
  }
`;

export const RightSidePoolInfo = styled.div<{isVerticalView : boolean}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  
  @media (min-width: 768px) {
    margin-left: ${(props) => (props.isVerticalView ? '0' : '48px')};
  }
`;

export const PoolTable = styled.div <{isVerticalView : boolean}>`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  flex-wrap: wrap;
  width: 100%;
  
  @media (min-width: 768px) {
    flex-wrap: ${(props) => (props.isVerticalView ? 'wrap' : 'nowrap')};
    width: auto;
  }
`;

export const PoolTableCol = styled.div<{isVerticalView : boolean}>`
  font-family: 'Cairo',sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: calc(50% - 8px);
  
  &.raising {
    width: 100%;
    align-items: start;
    padding-top: 12px;

    @media (min-width: 768px) {
      width: ${(props) => (props.isVerticalView ? '100%' : 'auto')};
      align-items: ${(props) => (props.isVerticalView ? 'start' : 'center')};
      padding-top: ${(props) => (props.isVerticalView ? '12px' : '0')};
    }
  }
  
  @media (min-width: 768px) {
    width: ${(props) => (props.isVerticalView ? 'calc(50% - 8px)' : 'auto')};
  }
  
  .col-name {
    font-family: inherit;
    font-weight: 400;
    font-size: ${(props) => (props.isVerticalView ? '20px' : '16px')};
    line-height: 24px;
    color: ${Color.darkPrimary};
  }
  
  .col-data {
    font-weight: 700;
    font-size: ${(props) => (props.isVerticalView ? '32px' : '20px')};
    line-height: 150%;
    color: ${Color.darkPrimary};
    
    &.raising {
      color: ${Color.white};
    }
  }
`;
export const RegistrationEndsWrapper = styled.div`
  width: 100%;
  
  .time-to-end {
    font-family: 'Cairo',serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.darkPrimary};
    padding-bottom: 8px;
  }
`;

export const ProgressBar = styled.div<{ progress: number;}>`
  background: rgba(255, 255, 255, 0.04);
  border-radius: 97px;
  height: 11px;
  
  .filled {
    display: block;
    height: inherit;
    background: linear-gradient(88.83deg, #EEEEEE -290.8%, #FFFEE2 -272.69%, rgba(255, 255, 255, 0.950883) -259.54%, #DF68FD -244.29%, rgba(255, 255, 255, 0.815523) -226.25%, #B7EEE5 -202.79%, #20282E -142%, #29353C -122.65%, rgba(255, 255, 255, 0.596267) -113.78%, #C9FBFE -93.45%, #79A2F2 -77.51%, rgba(255, 255, 255, 0.741036) -63.62%, #79A2F2 -47.78%, #5D23FD -19.47%, #FFFFFF 10.99%, #51555E 99.71%, #699CFF 127%, #FFFFFF 133.99%, #79A2F2 141.27%, #FFFFFF 147.64%);
    border-radius: 97px;
    width: ${(props) => (props.progress ? `${props.progress}%` : 0)};
  }
`;
