import {Cell, ContactRowWrapper, ContactWrapper, H3} from "./style";
import {GameModel} from "../../../../../logic/actions";

export interface Contact {
  name: string,
  link: string,
  isPreferred: boolean
}

export const ContactInfo = ({game} : {game: GameModel}): JSX.Element => {
  const {pitchDeckLink, email, websiteUrl, telegram} = game;

  const data = [
    {
      name: 'Web site URL',
      link: websiteUrl,
      isPreferred: false
    },
    {
      name: 'Pitch Deck Link',
      link: pitchDeckLink,
      isPreferred: false
    },
    {
      name: 'Contact Email',
      link: email,
      isPreferred: true
    },
    {
      name: 'Telegram',
      link: telegram,
      isPreferred: false
    }
  ]

  return (
    <ContactWrapper>
      <H3>
        Contact Info
      </H3>

      {
        data.length && data.map((i, index) => <ContactRow contact={i} key={index}/>)
      }
    </ContactWrapper>
  )
}

const ContactRow = ({contact} : { contact: Contact }) : JSX.Element => {
  return (
    <ContactRowWrapper>
      <Cell>{contact.name}</Cell>
      <Cell className="link"><a href={contact.link}>{contact.link}</a></Cell>
      <Cell className="preferred">{contact.isPreferred ? 'Preferred Way' : ''}</Cell>
    </ContactRowWrapper>
  )
}
