import styled from "styled-components";
import {Color, Theme} from "../../../../utils";

interface Props {
  show: boolean;
}

export const ModalWrapper = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.16), inset 0px 0px 2px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(8px);
  background-color: ${Theme.modalBg};
  display: ${(props) => (props.show ? "block" : "none")};
  z-index: ${(props) => (props.show ? "3" : "-3")};
  opacity: ${(props) => (props.show ? "1" : "0")};
`;

export const H3 = styled.h3`
  font-family: inherit;
  font-weight: 400;
  font-size: 32px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 0.01em;
  padding-top: 45px;
  color: ${Color.white};
`;

export const ModalContainer = styled.div`
  width: 600px;
  padding: 46px 50px;
  box-shadow: ${Theme.modalShadow};
  position: relative;
  font-family: 'Cairo', serif;
  background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
  backdrop-filter: blur(20px);
  border-radius: 24px;

  @media (max-width: 600px) {
    width: 100%;
    margin: 0;
    padding: 50px 30px 41px 30px;
  }

  @media (max-width: 500px) {
    max-height: 99%;
    overflow-y: auto;
  }

  .description {
    font-family: inherit;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: ${Color.white};
    padding: 14px 0 24px;
  }
`;

export const Header = styled.div`
  font-weight: 700;
  font-family: inherit;
  font-size: 20px;
  line-height: 37px;
  color: ${Color.white};
  padding-bottom: 26px;
  border-bottom: 1px solid #4F5258;
`;

export const CrossIconContainer = styled.div`
  position: absolute;
  top: -25px;
  right: -30px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const ModalBorder = styled.div`
  position: relative;
  padding: 1px;
  width: 600px;
  border-radius: 12px;
  margin: 105px auto 0;


  @media screen and (max-width: 586px) {
    width: 95vw;
    margin: 10px;
    padding: 1px;
    height: auto;
  }
`;

export const ButtonContainer = styled.div`{
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;

  .btn-action-link {
    background: rgba(255, 255, 255, 0.02);
    box-shadow: 0px 24px 48px rgba(255, 255, 255, 0.1), inset 0px 4px 11px rgba(0, 0, 0, 0.16), inset 0px 0px 2px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
  }
}`;
