/* eslint-disable @typescript-eslint/no-explicit-any */
import { plainToInstance } from 'class-transformer';
import { get } from 'lodash';

import { Api } from '../../../lib/network';
import { BaseModel } from '../base';

import {ADD_GAME, APPROVE_GAME, DECLINE_GAME, FIND_PRIVATE_WHITELIST, FIND_PUBLIC_WHITELIST, FIND_SAFELIST, GAME, GAME_LISTS} from './queries';

export class Game extends BaseModel {
  id: number;
  email: string;
  status: string;

  static fromJson(json: any) {
    return plainToInstance(Game, json);
  }
  static fromJsonArray(arrJson: any[]) {
    return arrJson ? arrJson.map((x) => Game.fromJson(x)) : [];
  }

  static async add(data: any) {
    const { data: _, ...rest } = await Api.request(ADD_GAME, {
      data: data,
    });
    return { ...rest };
  }

  static async findGames() {
    const { data, ...rest } = await Api.request(GAME_LISTS, {});

    return {
      ...rest,
      games: get(data, 'findGamesByAdmin', [])
    };
  }

  static async findGame(id: number) {
    const { data, ...rest } = await Api.request(GAME, {id});

    return {
      ...rest,
      ...get(data, 'findGameById', undefined)
    };
  }

  static async approve(id: number) {
    const { data, ...rest } = await Api.request(APPROVE_GAME, {
      id,
    });
    return { ...rest };
  }

  static async decline(id: number) {
    const { data, ...rest } = await Api.request(DECLINE_GAME, {
      id,
    });
    return { ...rest };
  }

  static async findSafelist(id: number) {
    const { data } = await Api.request(FIND_SAFELIST, {
      id,
    });

    return get(data, 'findSafelistById', []);
  }

  static async findPrivateWhitelist(id: number) {
    const { data } = await Api.request(FIND_PRIVATE_WHITELIST, {
      id,
    });

    return get(data, 'findPrivateWhitelist', []);
  }

  static async findPublicWhitelist(id: number) {
    const { data } = await Api.request(FIND_PUBLIC_WHITELIST, {
      id,
    });

    return get(data, 'findPublicWhitelist', []);
  }
}
