import React, {useState} from "react";
import {H3, Rectangle, Title} from "../account/pool-list/style";
import {ArrowLeft} from "../../images";
import {Table, TableHeader, Th, Tr, Wrapper} from "../safelist/style";
import AddUserModal from "./modal/add-user-modal";

const users = [
  {
    id: 1,
    poolName: "Pool Name",
    userName: "User 1",
    wallet: "0x90FB363AEF304FEE0265A6e04061dFf664A48C72"
  },
  {
    id: 2,
    poolName: "Pool Name",
    userName: "User 2",
    wallet: "0x90FB363AEF304FEE0265A6e04061dFf664A48C72"
  },
  {
    id: 3,
    poolName: "Pool Name",
    userName: "User 3",
    wallet: "0x90FB363AEF304FEE0265A6e04061dFf664A48C72"
  },
  {
    id: 4,
    poolName: "Pool Name",
    userName: "User 4",
    wallet: "0x90FB363AEF304FEE0265A6e04061dFf664A48C72"
  }
]

const AuthUsers: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);

  const handleClick = () => {
    setShow(true);
  }

  const handleCloseModal = () => {
    setShow(false);
  }


  const handleBack = () => {
  }

  return (
      <>
        <Title onClick={handleBack} asButton={true}>
          <H3>
            <img src={ArrowLeft.default} alt=""/>
            Go Back
          </H3>
          <Rectangle/>
        </Title>
        <Wrapper>
          <TableHeader>
            <h3>Authorized users</h3>

            <button
              type="button"
              className="btn-action-link"
              onClick={handleClick}
            >
              ADD USER
            </button>
          </TableHeader>
          <Table>
            <thead>
              <Tr header>
                <Th>Pool Name</Th>
                <Th>User Name</Th>
                <Th>Wallet Address</Th>
                <Th></Th>
              </Tr>
            </thead>
            <tbody>
            {users.length > 0 &&
            users.map((i: any) => (
              <Tr header key={i.id}>
                <Th>
                  {i.poolName}
                </Th>
                <Th>
                  {i.userName}
                </Th>
                <Th>
                  {i.wallet}
                </Th>
                <Th className="center">
                  <button
                    type="button"
                    className="btn-action-link"
                    onClick={() => true}
                  >
                    REMOVE
                  </button>
                </Th>
              </Tr>
            ))}
            </tbody>
          </Table>
        </Wrapper>

        <AddUserModal show={show} closeModal={handleCloseModal} />
      </>
  )
}

export default AuthUsers;
