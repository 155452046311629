/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  GameInfo,
  H2,
  Wrapper,
  About,
  DateWrapper,
  ButtonContainer,
  BorderLine,
  Link,
} from "./style";
import { DefaultGameImage } from "../../../../images";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { PoolInfo } from "../../components/pool-info";
import { GameModel } from "../../../../logic/actions";
import store from "../../../../logic/reducers";

export const PoolCard = ({
  game,
  openApproveModal,
  openDeclineModal,
}: {
  game: GameModel;
  openApproveModal: any;
  openDeclineModal: any;
}): JSX.Element | null => {
  const [pool, setPool] = useState<GameModel>();

  useEffect(() => {
    setPool(game);
  }, []);

  store.subscribe(() => {
    if (game) {
      const data = store
        .getState()
        .games.games.find((i: GameModel) => i.id === game.id);
      setPool(data);
    }
  });

  return (
    <>
      {pool && (
        <>
          <Wrapper isActivePole={!!game.poolInfo}>
            <GameInfo>
              <img
                className="round-corner-md"
                src={pool.thumbnail ? pool.thumbnail.split(',')[0] : DefaultGameImage.default}
                alt="Meme"
              />
              <div className="wrapper">
                <H2>{pool.name}</H2>
                <span>{pool.raiseAmount}</span>
              </div>
            </GameInfo>

            <About>
              <H2>About project</H2>
              <div className="description">{pool.description}</div>
            </About>

            <DateWrapper>
              {format(new Date(pool.createdAt), "MMM-dd-yyyy")}
            </DateWrapper>

            {game.poolInfo && <BorderLine />}

            {!!game.poolInfo && (
              <PoolInfo poolInfo={game.poolInfo} isVerticalView={false} />
            )}
          </Wrapper>
          <ButtonContainer>
            <div />
            <div>
              <Link
                to={`/project-full-info/${pool.id}`}
                className="btn-action-link"
              >
                See Full Info
              </Link>

              {!pool.isApproved && (
                <a
                  onClick={() => openApproveModal(pool.id, pool.user?.walletAddress)}
                  className="btn-action-link"
                >
                  Approve
                </a>
              )}

              {pool.isApproved && (
                <a 
                  onClick={() => openDeclineModal(pool.id, pool.user?.walletAddress)}
                  className="btn-action-link bottom-border-none"
                >
                  Disapprove
                </a>
              )}
            </div>
          </ButtonContainer>
        </>
      )}
    </>
  );
};
